<template>
  <div class="sign-wrapper_mobile">
    <user-sign-logo class="sign-in-mobile-wrapper">
      <UserSignInForm :isMobile="true" :copyright="copyright"/>
  </user-sign-logo>
  </div>
  
</template>

<script>
import UserSignLogo from "@/pages/user/Logo.vue";
import UserSignInForm from "@/pages/user/SignInForm.vue";
export default {
  name: "UserSignInMobile",
  components: {
    UserSignLogo,
    UserSignInForm
  },
  data() {
    return {
      copyright: `Copyright @ 2021-${new Date().getFullYear()} echeck. All Rights Reserved`
    };
  },
  computed: {},
  methods: {}
};
</script>

<style lang="less" scoped>
@import url("../../styles/mobile.less");
</style>
