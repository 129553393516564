<template>
  <a-modal
    class="user-verification-reset-password"
    v-model="innerVisible"
    :title="$t('button.resetPwd')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    :maskClosable="false"
    destroyOnClose
    :confirm-loading="pending"
  >
    <UserVerifyResetPwd ref="indexRef" :user-id="userId" />
  </a-modal>
</template>

<script>
import UserBindEmail from "@/pages/user/Email/Index.vue";
import UserVerifyResetPwd from "@/pages/user/VerifyResetPwd/Index.vue";

export default {
  name: "UserVerifyResetPwdModal",
  components: { UserVerifyResetPwd },
  props: {
    visible: Boolean,
    userId: String
  },
  data() {
    return {
      pending: false
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const data = await this.$refs.indexRef.onValid();
      if (!data) {
        this.pending = false;
        return;
      }
      this.$emit("confirm", data);
      this.pending = false;
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped></style>
