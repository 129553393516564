<template>
  <a-modal
    class="user-bing-email"
    v-model="innerVisible"
    :title="$t('user.bindEmail')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    :maskClosable="false"
    destroyOnClose
    :confirm-loading="pending"
  >
    <UserBindEmail ref="indexRef" :list="list" :username="username" />
  </a-modal>
</template>

<script>
import UserBindEmail from "@/pages/user/Email/Index.vue";

export default {
  name: "UserBindEmailModal",
  components: { UserBindEmail },
  props: {
    visible: Boolean,
    list: {
      required: true,
      type: Array,
    },
    username: String,
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const data = await this.$refs.indexRef.onValid();
      if (!data) {
        this.pending = false;
        return;
      }
      this.$emit("confirm", data);
      this.pending = false;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
