import moment from "moment/moment";
import { DateFormat } from "@/config";

export const datetimeFilter = {
  data() {
    return {
      // 当前时间
      currentDatetime: moment(),
      // 时间字符格式化格式
      datetimeFilterFormatter: DateFormat,
      // 是否计算到秒
      datetimeFilterDownToSeconds: false,
      // 获取列表 slice方法
      datetimeFilterListStartIndex: 0,
      datetimeFilterListEndIndex: 10,
    };
  },
  computed: {
    datetimeFilterList() {
      const curr = this.currentDatetime;
      const todayStart = this.setDatetimeFilterValue(moment(curr), true);
      const todayEnd = this.setDatetimeFilterValue(moment(curr));
      const yesterdayStart = this.setDatetimeFilterValue(moment(curr).subtract(1, "days"), true);
      const yesterdayEnd = this.setDatetimeFilterValue(moment(curr).subtract(1, "days"));
      const weekStart = this.setDatetimeFilterValue(moment(curr).subtract(moment(curr).format("E") - 1, "days"), true);
      const weekEnd = this.setDatetimeFilterValue(moment(curr).add(7 - moment(curr).format("E"), "days"));
      const lastWeekStart = this.setDatetimeFilterValue(moment(curr).subtract(moment(curr).format("E") - 1 + 7, "days"), true);
      const lastWeekEnd = this.setDatetimeFilterValue(moment(curr).subtract(moment(curr).format("E"), "days"));
      const monthStart = this.setDatetimeFilterValue(moment(curr).startOf("months"), true);
      const monthEnd = this.setDatetimeFilterValue(moment(curr).endOf("months"));
      const lastMonthStart = this.setDatetimeFilterValue(moment(curr).subtract(1, "months").startOf("month"), true);
      const lastMonthEnd = this.setDatetimeFilterValue(moment(curr).subtract(1, "months").endOf("month"));
      const quarterStart = this.setDatetimeFilterValue(moment(curr).startOf("quarter"), true);
      const quarterEnd = this.setDatetimeFilterValue(moment(curr).endOf("quarter"));
      const lastQuarterStart = this.setDatetimeFilterValue(moment(curr).subtract(1, "quarters").startOf("quarter"), true);
      const lastQuarterEnd = this.setDatetimeFilterValue(moment(curr).subtract(1, "quarters").endOf("quarter"));
      const yearStart = this.setDatetimeFilterValue(moment(curr).startOf("year"), true);
      const yearEnd = this.setDatetimeFilterValue(moment(curr).endOf("year"));
      const lastYearStart = this.setDatetimeFilterValue(moment(curr).subtract(1, "years").startOf("year"), true);
      const lastYearEnd = this.setDatetimeFilterValue(moment(curr).subtract(1, "years").endOf("year"));

      const list = [
        {
          label: this.$t("datetime.today"),
          key: 1,
          startTime: todayStart,
          endTime: todayEnd,
          startTimeStr: todayStart.format(this.datetimeFilterFormatter),
          endTimeStr: todayEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.yesterday"),
          key: 2,
          startTime: yesterdayStart,
          endTime: yesterdayEnd,
          startTimeStr: yesterdayStart.format(this.datetimeFilterFormatter),
          endTimeStr: yesterdayEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.week"),
          key: 3,
          startTime: weekStart,
          endTime: weekEnd,
          startTimeStr: weekStart.format(this.datetimeFilterFormatter),
          endTimeStr: weekEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.lastWeek"),
          key: 4,
          startTime: lastWeekStart,
          endTime: lastWeekEnd,
          startTimeStr: lastWeekStart.format(this.datetimeFilterFormatter),
          endTimeStr: lastWeekEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.month"),
          key: 5,
          startTime: monthStart,
          endTime: monthEnd,
          startTimeStr: monthStart.format(this.datetimeFilterFormatter),
          endTimeStr: monthEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.lastMonth"),
          key: 6,
          startTime: lastMonthStart,
          endTime: lastMonthEnd,
          startTimeStr: lastMonthStart.format(this.datetimeFilterFormatter),
          endTimeStr: lastMonthEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.quarter"),
          key: 7,
          startTime: quarterStart,
          endTime: quarterEnd,
          startTimeStr: quarterStart.format(this.datetimeFilterFormatter),
          endTimeStr: quarterEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.lastQuarter"),
          key: 8,
          startTime: lastQuarterStart,
          endTime: lastQuarterEnd,
          startTimeStr: lastQuarterStart.format(this.datetimeFilterFormatter),
          endTimeStr: lastQuarterEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.year"),
          key: 9,
          startTime: yearStart,
          endTime: yearEnd,
          startTimeStr: yearStart.format(this.datetimeFilterFormatter),
          endTimeStr: yearEnd.format(this.datetimeFilterFormatter),
        },
        {
          label: this.$t("datetime.lastYear"),
          key: 10,
          startTime: lastYearStart,
          endTime: lastYearEnd,
          startTimeStr: lastYearStart.format(this.datetimeFilterFormatter),
          endTimeStr: lastYearEnd.format(this.datetimeFilterFormatter),
        },
      ];
      return list.slice(this.datetimeFilterListStartIndex, this.datetimeFilterListEndIndex);
    },
  },
  methods: {
    setDatetimeFilterValue(datetime, isStart) {
      if (this.datetimeFilterDownToSeconds) {
        if (isStart) {
          return moment(datetime).startOf("date");
        }
        return moment(datetime).endOf("date");
      }
      return moment(datetime);
    },
  },
};
