<template>
  <!-- 滑动区域 -->
  <div class="slide-container">
    <div class="slide-progress"></div> 
    <!--  拖动滑块  -->
    <div class="slide-drag">
      <a-icon type="right" class="slide-drag_arrow" />
    </div>
    <!--    滑动文字-->
    <div class="slide-text" :class="lang">
      <div class="slide-text_real">{{ $t("user.slideTip") }}</div>
      <!--    滑动文字效果  -->
      <div class="slide-text_shadow" />
    </div>
  </div>
</template>

<script>
import { langMixin } from "@/mixins";
import { isWap } from "@/utils/tools";

export default {
  name: "EcSlider",
  data() {
    return {
      isSlideSuccess: false,
      container: "",
      block: "",
      slideText: "",
      shadow: "",
    };
  },
  mixins: [langMixin],
  methods: {
    mobileListener(event) {
      const { pageX: mouseOffsetX } = event.touches[0];
      if (this.isSlideSuccess) {
        return;
      }
      this.isSlideSuccess = false;
      const {
        containerOffsetL,
        containerOffsetW,
        blockOffsetW,
        progress
      } = this.getElementSize();
      const touchMove = (e) => {
        let { clientX } = e.touches[0];
        let safeX = clientX - containerOffsetL - mouseOffsetX;
        safeX = Math.max(safeX, 0);
        let maxOffsetX = containerOffsetW - blockOffsetW - 4;
        safeX = Math.min(safeX, maxOffsetX);
        this.isSlideSuccess = safeX === maxOffsetX;
        this.block.style.left = safeX + "px";
        progress.style.width = safeX + "px";
      };
      const touchEnd = (e) => {
        if (this.isSlideSuccess) {
          this.block.style.left = "initial";
          this.block.style.right = "4px";
          progress.innerHTML = this.$t("user.slideSuccess");
        } else {
          this.block.style.left = "4px";
          this.shadow.style.display = "block";
          progress.style.width = 0;
          if (progress.innerHTML) {
            progress.innerHTML = "";
          }
        }
        this.$emit("success", this.isSlideSuccess);
        document.removeEventListener("touchend", touchEnd);
        document.removeEventListener("touchmove", touchMove);
      };
      document.addEventListener("touchend", touchEnd);
      document.addEventListener("touchmove", touchMove);
    },
    getElementSize() {
      this.container = document.querySelector(".slide-container");
      this.block = document.querySelector(".slide-drag");
      this.slideText = document.querySelector(".slide-text_real");
      this.shadow = document.querySelector(".slide-text_shadow");
      const progress = document.querySelector(".slide-progress");
      const { offsetWidth: containerOffsetW } = this.container;
      const containerOffsetL = this.container.getBoundingClientRect().left;
      const { offsetWidth: blockOffsetW } = this.block;
      return { blockOffsetW, containerOffsetL, containerOffsetW, progress };
    },
    listener(event) {
      const { layerX: mouseOffsetX } = event;
      const {
        containerOffsetL,
        containerOffsetW,
        blockOffsetW,
        progress
      } = this.getElementSize();
      if (this.isSlideSuccess) {
        return;
      }
      this.isSlideSuccess = false;
      const mousemove = (e) => {
        let { clientX } = e;
        let safeX = clientX - containerOffsetL - mouseOffsetX;
        safeX = Math.max(safeX, 0);
        let maxOffsetX = containerOffsetW - blockOffsetW - 4;
        safeX = Math.min(safeX, maxOffsetX);
        this.isSlideSuccess = safeX === maxOffsetX;
        this.block.style.left = safeX + "px";
        progress.style.width = safeX + "px";
      };
      const mouseup = (e) => {
        if (this.isSlideSuccess) {
          this.block.style.left = "initial";
          this.block.style.right = "4px";
          progress.innerHTML = this.$t("user.slideSuccess");
        } else {
          this.block.style.left = "4px";
          this.shadow.style.display = "block";
          progress.style.width = 0;
          if (progress.innerHTML) {
            progress.innerHTML = "";
          }
        }
        this.$emit("success", this.isSlideSuccess);
        document.removeEventListener("mouseup", mouseup);
        document.removeEventListener("mousemove", mousemove);
      };
      document.addEventListener("mouseup", mouseup);
      document.addEventListener("mousemove", mousemove);
    },
    initSlide() {
      this.getElementSize();
      if (isWap) {
        this.block.addEventListener("touchstart", this.mobileListener);
      } else {
        this.block.addEventListener("mousedown", this.listener);
      }
    },
    resetBlock() {
      this.isSlideSuccess = false;
      const { progress } = this.getElementSize();
      this.$nextTick(() => {
        progress.style.width = 0;
        progress.innerHTML = "";
        if (this.block) {
          this.block.style.right = "initial";
          this.block.style.left = "4px";
        }
      });
      this.$emit("success", this.isSlideSuccess);
    },
    preventDefault(e) {
      e.preventDefault();
    },

    clearSlide() {
      this.resetBlock();
      if (isWap) {
        document.removeEventListener("touchstart", this.mobileListener);
      } else {
        document.removeEventListener("mousedown", this.listener);
      }
    },
  },
  mounted() {
    this.initSlide();
  },
  beforeDestroy() {
    this.clearSlide();
  },
};
</script>

<style lang="less" scoped>
.slide-container {
  width: 100%;
  height: 50px;
  background: #f4f4f4;
  position: relative;
  .slide-progress {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 42px;
    width: 0;
    text-align: center;
    line-height: 42px;
    background-color: #266FE8;
    z-index: 2;
    color: #fff;
  }
  .slide-drag {
    user-select: none;
    cursor: all-scroll;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: #fff;
    text-align: center;
    z-index: 3;

    &_arrow {
      font-size: 20px;
      color: @primary-color;
      line-height: 42px;
    }
  }

  .slide-text {
    width: 180px;
    margin: 0 auto;
    text-align: center;
    line-height: 50px;
    position: relative;
    color: #9c9c9c;
    user-select: none;

    &.en {
      width: 310px;
    }

    &_real {
    }

    @keyframes slideVisible {
      100% {
        left: 100%;
      }

      50% {
        left: 50%;
      }

      0% {
        left: 0;
      }
    }

    &_shadow {
      width: 16px;
      height: 4px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      box-shadow: 0 0 8px 6px #fff;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      animation: slideVisible 2s linear infinite;
    }
  }
}
</style>
