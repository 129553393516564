<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    class="sign-in-bind-email-form"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <div class="sign-in-bind-email-form-tips">
      <i class="icon-tip"></i>
      {{ $t("user.bindEmailTips") }}
      {{ $t("user.bindEmailTips2") }}
    </div>
    <a-form-model-item label="" prop="userId">
      <a-radio-group v-model="form.userId" class="sign-in-bind-email-radio">
        <a-radio
          :value="it.contact.userId"
          v-for="it in list"
          :key="it.contact.userId"
        >
          <div class="sign-in-bind-email-radio-text">
            <span>{{ it.contact.email }}</span>
            <span>{{ it.company.name }}</span>
          </div>
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { formItemLayout, formTailLayout } from "@/pages/user/config";
import { confirmModal } from "@/utils/tools";

const Empty = {
  userId: ""
};

export default {
  name: "UserBindEmail",
  props: {
    list: {
      required: true,
      type: Array
    },
    username: String
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: { ...Empty },
      rules: {
        userId: [
          { required: true, message: this.$t("user.bindEmailRequired") }
        ]
      }
    };
  },
  methods: {
    onValid() {
      return new Promise(resolve => {
        this.$refs.formRef.validate(async (valid, errors) => {
          if (!valid) {
            resolve(false);
            return;
          }
          const curr = this.list.find(
            it => it.contact.userId === this.form.userId
          );
          const confirmed = await confirmModal(this.$t("user.bindEmail"), h => (
            <div>
              <div>
                {this.$t("user.bindEmailConfirm1")}
                <strong style="color: #F03738;">{curr?.contact.email}</strong>
                {this.$t("user.bindEmailConfirm2")}
                <strong style="color: #F03738;">{this.username}</strong>
                {this.$t("user.bindEmailConfirm3")}
              </div>
              <div>{this.$t("user.bindEmailTips2")}</div>
            </div>
          ));
          if (!confirmed) {
            resolve(false);
            return;
          }
          resolve({ ...this.form, companyId: curr?.company.companyId });
        });
      });
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.sign-in-bind-email-form {
  .sign-in-bind-email-form-tips {
    background: #fff5e6;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #ffbc36;
    padding: 10px 20px;
    margin-bottom: 15px;

    .icon-tip {
      display: inline-block;
      width: 16px;
      transform: translateY(3px);
    }
  }
  .sign-in-bind-email-radio {
    /deep/.ant-radio-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 10px;

      .ant-radio {
        transform: translateY(5px);
      }
      .sign-in-bind-email-radio-text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}
</style>
