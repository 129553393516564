<template>
  <div class="sign-in-form-wrapper">
    <a-form-model ref="formRef" :model="form" :rules="rules" class="sign-in-form" :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol">
      <h3 class="form-title" v-if="!isMobile">
        <div class="form-tabs">
          <a v-for="tab in loginTypeListShown" :key="tab.key" href="javascript: void(0);" class="form-tab"
            :class="loginType === tab.key ? 'active' : ''" @click="onToggleLoginType(tab.key)">
            {{ tab.label }}
          </a>
        </div>
        <a-select :getPopupContainer="trigger => trigger.parentNode" :value="lang" @change="handleLangChange"
          class="form-lang">
          <a-select-option :value="item.key" v-for="item of langList" :key="item.key">
            {{ item.na }}
          </a-select-option>
        </a-select>
      </h3>
      <div class="form-content">
        <div class="form-item">
          <label for="username" class="form-label">{{
      $t("user.username.label")
    }}</label>
          <a-form-model-item label="" prop="username">
            <a-input size="large" v-model="form.username" :placeholder="$t('user.username.placeholder')"
              autocomplete="off" @keyup.enter="onNextFocus('userSignIn_password')" id="userSignIn_username">
              <img src="@/assets/images/user/password/LoginIco_User.png" alt="" slot="prefix"
                class="user-icon icon-phone" />
            </a-input>
          </a-form-model-item>
        </div>
        <div class="form-item" v-if="loginType === LOGIN_TYPE.USER_PASSWORD">
          <label for="username" class="form-label">{{
      $t("user.password.label")
    }}</label>
          <a-form-model-item label="" prop="password">
            <a-input size="large" type="password" v-model="form.password" :placeholder="$t('user.password.placeholder')"
              autocomplete="off" @keyup.enter="submit" id="userSignIn_password">
              <img src="@/assets/images/user/password/LoginIco_PassWord.png" alt="" slot="prefix"
                class="user-icon icon-pwd" />
            </a-input>
          </a-form-model-item>
        </div>
        <div class="form-item" v-if="loginType === LOGIN_TYPE.MOBILE_SMS_CODE">
          <label for="userSignIn_code" class="form-label">{{
      $t("user.mobileCode.label")
    }}</label>
          <a-form-model-item label="" prop="code">
            <a-input size="large" type="text" v-model="form.code" :placeholder="$t('user.mobileCode.placeholder')"
              autocomplete="off" @keyup.enter="submit" id="userSignIn_code">
              <img src="@/assets/images/user/password/LoginIco_PassWord.png" alt="" slot="prefix"
                class="user-icon icon-pwd" />
              <a href="javascript: void(0);" class="form-code-img" @click="sendSMSCode" slot="addonAfter">
                {{ codeSeconds > 0 ? `${codeSeconds}${$t("user.mobileCode.counterText")}` :
      $t("user.mobileCode.suffix") }}
              </a>
            </a-input>
          </a-form-model-item>
        </div>
      </div>
      <template v-if="this.isMobile">
        <div class="form-link form-link-mobile">
          <a href="javascript: void(0);" @click="onToggleLoginType(k.key)" v-for="k in loginTypeListShown" :key="k.key">
            {{ k.label }}
          </a>
        </div>
      </template>
      <!-- 拖拽滑块 👇 -->
      <EcSlider ref="slider" @success="getIsSlide"/>
        <!-- 拖拽滑块 👆 -->
      <template v-if="!isMobile && loginType===LOGIN_TYPE.USER_PASSWORD">
        <div class="form-middle">
          <div class="form-remember">
            <a-checkbox :checked="remember" @change="onRemember">
              {{ $t("user.remember") }}
            </a-checkbox>
          </div>
          <div class="form-link">
            <a href="javascript: void(0);" @click="onPassword">
              {{ $t("user.forget") }}
            </a>
          </div>
        </div>
      </template>
      <div class="form-foot">
        <a-button type="primary" @click="submit" class="sign-in-submit" :loading="pending">
          {{ $t("user.submit") }}
        </a-button>
      </div>
      <!-- <template v-if="!isMobile">
        <div class="form-foot-link-wrap">
          {{ $t("user.signUpTip2")
          }}<a class="form-foot-link" href="javascript: void(0);" @click="onSignUp">
            {{ $t("user.signUpSubTip2") }}
          </a>
        </div>
      </template> -->
      <div class="form-copyright" v-html="copyright"></div>
    </a-form-model>
    <user-password :visible.sync="password.visible" />
    <UserSelectCompanyModal :visible.sync="companyVisible" :username="form.username" @confirm="onCompanyConfirm"
      @close="onCompanyClose" />
    <UserVerificationCodeModal :visible.sync="verificationCodeVisible" :username="form.username"
      @confirm="onVerificationCodeConfirm" @close="onVerificationCodeClose" />
    <UserBindEmailModal :visible.sync="bindEmailVisible" :username="form.username" :list="bindEmailList"
      @confirm="onBindEmailConfirm" @close="onBindEmailClose" />
    <UserVerifyResetPwdModal :visible.sync="verifyResetPwdVisible" :user-id="verifyResetPwdData.userId"
      @confirm="onVerifyResetPwdConfirm" @close="onVerifyResetPwdClose" />
    <UserSMSLoginModal :visible.sync="smsLoginVisible" :username="form.username" :confirm="onSMSLoginConfirm"
      @close="onSMSLoginClose" />
  </div>
</template>

<script>
import { showMsg } from "@/utils";
import { cookies, OLD_WEBSITE, RES_CODE, STORAGE_KEYS, REG_EMAIL, REG_NUM } from "@/config";
import { mapState } from "vuex";
import UserPassword from "./Password";
import UserSelectCompanyModal from "./Company/Modal.vue";
import { formItemLayout, formTailLayout } from "@/pages/user/config";
import { onLogin, signInSendCode } from "@/pages/user/api";
import { FETCH_CODE } from "@/config/fetch";
import { LOGIN_TYPE } from "@/config";
import EcSlider from "@/components/EcSlider.vue";
import UserVerificationCodeModal from "@/pages/user/Code/Modal.vue";
import UserBindEmailModal from "@/pages/user/Email/Modal.vue";
import UserVerifyResetPwdModal from "@/pages/user/VerifyResetPwd/Modal.vue";
import UserSMSLoginModal from "@/pages/user/SMSLogin/Modal.vue";
import { langMixin } from "@/mixins";
import { getType } from "@/utils/tools";
import { trim } from "lodash";

const Empty = {
  username: "",
  password: "",
  code: ""
};

export default {
  name: "UserSignInForm",
  props: {
    copyright: String,
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserVerifyResetPwdModal,
    UserBindEmailModal,
    UserVerificationCodeModal,
    UserPassword,
    EcSlider,
    UserSelectCompanyModal,
    UserSMSLoginModal
  },
  mixins: [langMixin],
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: { ...Empty },
      remember: false,
      pending: false,
      loginData: {},

      //#region 找回密码
      password: {
        visible: false
      },
      //#endregion

      //#region 选择登录主体
      companyList: [],
      companyVisible: false,
      //#endregion

      //#region 绑定邮箱
      verificationCodeVisible: false,
      bindEmailList: [],
      bindEmailVisible: false,
      verifyResetPwdVisible: false,
      verifyResetPwdData: {
        userId: "",
        companyId: ""
      },
      //#endregion

      isSlideSuccess: false,
      LOGIN_TYPE,
      loginType: LOGIN_TYPE.USER_PASSWORD,
      loginTypeList: [
        { key: LOGIN_TYPE.USER_PASSWORD, label: this.$t("user.tabs.pwd") },
        { key: LOGIN_TYPE.MOBILE_SMS_CODE, label: this.$t("user.tabs.sms") },
      ],
      codeSeconds: 0,
      codeTimer: 0,
      codePending: false,
      // 弹窗验证码登录
      smsLoginVisible: false,
    };
  },
  computed: {
    ...mapState("user", {
      companyValue: state => state.company.value
    }),
    loginTypeListShown() {
      const ls = [];
      for (const it of this.loginTypeList) {
        if (this.loginType === it.key) {
          if (!this.isMobile) {
            ls.unshift(it);
          }
        } else {
          ls.push(it);
        }
      }
      return ls;
    },
    currentLoginType() {
      return this.loginTypeList.find(l => l.key === this.loginType) || {};
    },
    rules() {
      const ruleList = {
        username: [
          { required: true, message: this.$t("user.username.validation") }
        ]
      };
      if (this.loginType === LOGIN_TYPE.USER_PASSWORD) {
        ruleList.password = [
          { required: true, message: this.$t("user.password.validation") }
        ];
      }
      if (this.loginType === LOGIN_TYPE.MOBILE_SMS_CODE) {
        ruleList.code = [
          { required: true, message: this.$t("user.mobileCode.validation") }
        ];
      }
      return ruleList;
    }
  },
  methods: {
    onRemember(e) {
      this.remember = e.target.checked;
      cookies.EC_REMEMBER = this.remember ? "1" : "0";
    },
    verifyUserName(value) {
      if (REG_EMAIL.test(trim(value))) {
        return false;
      }

      if (!REG_NUM.test(trim(value))) {
        return false;
      }

      return true;
    },
    submit() {
      if (this.pending) return;
      this.$refs.formRef.validate(async (valid, errors) => {
        if (!this.verifyUserName(this.form.username)) {
          showMsg({ flag: 1, msg: this.$t("user.emailLoginWarning") });
          return;
        }

        if (!valid) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: Object.values(errors)[0]?.[0]?.message
          });
          this.pending = false;
          return;
        }
        if (!this.isSlideSuccess) {
          showMsg({ flag: 1, msg: this.$t("user.slideWarning") });
          return;
        }
        this.pending = true;

        // 请求登录接口
        const res = await onLogin({
          account: this.form.username,
          password: this.form.password,
          code: this.form.code,
          type: this.loginType
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          this.pending = false;
            this.$refs.slider.clearSlide();
          // 未绑定公司
          if (res.data.code === RES_CODE.UN_BIND_COMPANY.KEY) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: res.msg
            });
            // 弹出短信验证弹窗
            this.verificationCodeVisible = true;
            return;
          }
          // 未注册用户
          if (res.data.code === RES_CODE.UN_REGISTED.KEY) {
            if (this.isMobile) {
              showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: `${res.msg} ${this.$t("user.mobileSignIn")}`
              });
              return;
            }
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: res.msg
            });
            // 跳转注册页面
            // this.$router.push({
            //   path: "/user/sign-up",
            //   query: {
            //     contact: this.form.username
            //   }
            // });
            return;
          }
          // 未验证用户（首次登录）
          if (res.data.code === RES_CODE.UN_VERIFIED.KEY) {
            await showMsg({
              flag: FETCH_CODE.ERROR.KEY,
              msg: this.$t("user.unVerified")
            });
            // 弹出验证码登录弹窗
            this.smsLoginVisible = true;
            return;
          }
          //密码过期
          if (res.data.code === RES_CODE.EXPIRED_PASSWORD.KEY) {
            this.onPassword();

            await showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: this.$t("user.passwordExpired")
            });

            return;
          }
          //临时锁定
          if (res.data.code === RES_CODE.TEMPORARY_ACCOUNT_LOCK.KEY) {
            await showMsg({
              flag: FETCH_CODE.ERROR.KEY,
              msg: this.$t("user.temporaryLock",{min: res.data.msg})
            });

            return;
          }
          await showMsg(res);
          this.pending = false;
          return;
        }
        // 执行登录
        this.loginData = res.data;
        this.submitAfter();
      });
    },
    submitAfter() {
      // 设置cookie直接给cookie对象赋值即可
      cookies.EC_TOKEN = this.loginData.session;
      let redirectUri = "/";
      if (this.$route.query.from) {
        redirectUri = `${this.$route.query.from}?`;
        for (const othKey in this.$route.query) {
          if (othKey === "from") continue;
          redirectUri += `${othKey}=${this.$route.query[othKey]}&`;
        }
        redirectUri = redirectUri.replace(/([?&]$)/, "");
      }
      this.$store.dispatch("user/getUserInfo", {
        callback: () => {
          this.$store.dispatch("home/getMenuList", {
            // fix#174 https://echeck-china.coding.net/p/echeck-platform/bug-tracking/issues/174/detail
            callback: () => {
              this.$store.commit("home/onMenu", {
                value: redirectUri,
                useKeyName: "href"
              });
              this.$router.replace(redirectUri);
              this.pending = false;
            },
          });
        },
      });
    },
    handleLangChange(value) {
      this.$store.commit("setLang", { value });
    },
    onToggleLoginType(loginType) {
      if (this.loginType === loginType) return;
      this.loginType = loginType;
      if (this.loginType === LOGIN_TYPE.USER_PASSWORD) {
        this.form.code = "";
      }
      if (this.loginType === LOGIN_TYPE.MOBILE_SMS_CODE) {
        this.form.password = "";
      }
      this.$refs.slider.resetBlock();
    },

    //#region 找回密码
    onPassword() {
      this.password.visible = true;
    },
    //#endregion

    //#region 选择登录主体
    async onCompanyConfirm(values) {
      this.companyVisible = false;
      // 选择完登录主体 重新请求登录接口
      const res = await onLogin({
        account: this.form.username,
        password: this.form.password,
        code: this.form.code,
        type: this.loginType,
        companyId: values.companyId
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg(res);
        this.pending = false;
        return;
      }
      // 执行登录
      window.sessionStorage.setItem(STORAGE_KEYS.COMPANY, values.companyId);
      this.loginData = res.data;
      this.submitAfter();
    },
    onCompanyClose() {
      this.pending = false;
    },
    //#endregion

    //#region 绑定邮箱
    onVerificationCodeConfirm(data) {
      this.verificationCodeVisible = false;
      this.bindEmailList = data;
      // 打开绑定邮箱弹窗
      this.bindEmailVisible = true;
    },
    onVerificationCodeClose() {
      this.pending = false;
    },
    async onBindEmailConfirm(data) {
      this.bindEmailVisible = false;
      // 打开修改密码弹窗
      this.verifyResetPwdData = data;
      this.verifyResetPwdVisible = true;
    },
    onBindEmailClose() {
      this.pending = false;
    },
    async onVerifyResetPwdConfirm(data) {
      this.verifyResetPwdVisible = false;
      // 选择完邮箱 重新请求登录接口
      const res = await onLogin({
        account: this.form.username,
        password: data.newPassword,
        type: LOGIN_TYPE.USER_PASSWORD,
        userId: this.verifyResetPwdData.userId
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg(res);
        this.pending = false;
        return;
      }
      // 执行登录
      window.sessionStorage.setItem(
        STORAGE_KEYS.COMPANY,
        this.verifyResetPwdData.companyId
      );
      this.loginData = res.data;
      this.submitAfter();
    },
    onVerifyResetPwdClose() {
      this.pending = false;
    },
    //#endregion

    // 注册
    onSignUp() {
      //this.$router.push("/user/sign-up");
    },
    onNextFocus(id) {
      try {
        const target = document.getElementById(id);
        target.focus();
      } catch (err) {
        console.error(err);
      }
    },
    getIsSlide(flag) {
      this.isSlideSuccess = flag;
    },

    // 发送登录验证码
    async sendSMSCode() {
      if (this.codeSeconds > 0 || this.codePending) return;
      if (!this.form.username) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("user.username.validation")
        });
        return;
      }

      if(this.form.username.length!==11) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("user.mobileCode.chineseMainlandOnly")
        });
        return;
      }

      this.codePending = true;
      const res = await signInSendCode({
        mobile: this.form.username
      });
      this.codePending = false;
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("user.mobileCode.send")
      });
      this.codeSeconds = 60;
      this.codeTimer = setInterval(() => {
        this.codeSeconds -= 1;
        if (this.codeSeconds <= 0) {
          clearInterval(this.codeTimer);
        }
      }, 1000);
    },

    // 弹窗验证码登录
    async onSMSLoginConfirm(formData) {
      // 请求登录接口
      const res = await onLogin({
        account: formData.username,
        code: formData.smsCode,
        type: LOGIN_TYPE.MOBILE_SMS_CODE
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        // 未绑定公司
        if (res.data.code === RES_CODE.UN_BIND_COMPANY.KEY) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: res.msg
          });
          // 弹出短信验证弹窗
          this.verificationCodeVisible = true;
          return;
        }
        await showMsg(res);
        this.pending = false;
        return;
      }
      // 执行登录
      this.loginData = res.data;
      this.submitAfter();
    },
    onSMSLoginClose() {
      this.pending = false;
    },

    // 获取最新版本
    async getAppUpdates() {
      const localVersion = window.localStorage.getItem(STORAGE_KEYS.VERSION);
      fetch(`/version.json?v=${+new Date()}`)
        .then((res) => res.json())
        .then((res) => {
          if (!res || getType(res) !== "object" || !res.version) return;
          const serveVersion = res.version;
          const syncVersion = () => {
            window.localStorage.setItem(STORAGE_KEYS.VERSION, serveVersion);
          };
          // 本地未写入版本
          // 或 本地已经写入版本 判断是否最新版本
          if (!localVersion || localVersion < serveVersion) {
            this.$confirm({
              title: this.$t("app.updates"),
              icon: () => null,
              okText: this.$t("button.reload"),
              onOk: () => {
                syncVersion();
                window.location.reload();
              },
              onCancel: () => {},
            });
          }
        })
        .catch((err) => {
          console.log("Request App Server Version Failed...", err);
        });
    }
  },
  mounted() {
    // 登录页加载完成后 判断当前应用版本是否最新
    this.getAppUpdates();
  }
};
</script>

<style lang="less" scoped>
.sign-in-form {
  width: 539px;
  position: relative;
  padding: 10px;

  .form-title {
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .form-tabs {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: nowrap;
      gap: 10px;

      .form-tab {
        line-height: 1.2;
        color: rgba(0, 0, 0, 0.9);

        &.active {
          font-size: 2em;
          font-weight: bold;
          color: @primary-color;
        }
      }
    }
  }

  .form-lang {
    width: 120px;
    font-weight: normal;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .form-content {
    margin-top: 63px;

    .form-label {
      font-size: 16px;
      font-weight: 400;
      color: #a3aab2;
      line-height: 40px;
      margin-top: 17px;
    }

    .iconfont {
      font-size: 20px;
    }

    /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }

    .form-code-img {
      display: block;
      height: 46px;
      width: 102px;
      cursor: pointer;
      text-align: center;
      line-height: 46px;
    }
  }

  .slide-container {
    margin-top: 29px;
  }

  .form-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .form-remember {
      font-size: 16px;
    }
  }

  .form-foot {
    margin-top: 47px;

    .sign-in-submit {
      width: 100%;
    }

    &-link {
      &-wrap {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 400;
        color: #aab0b8;
        text-align: center;
      }
    }

    &-btn {
      text-align: center;
      margin: 30px auto 0 auto;
      display: block;
    }
  }

  .form-copyright {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
