<template>
  <a-modal
    class="user-select-company"
    v-model="visible"
    :title="$t('user.selectCompany')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    :maskClosable="false"
    destroyOnClose
  >
    <UserSelectCompany ref="indexRef" :username="username" />
  </a-modal>
</template>

<script>
import UserSelectCompany from "./Index.vue";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";

export default {
  name: "UserSelectCompanyModal",
  components: { UserSelectCompany },
  props: {
    visible: Boolean,
    username: {
      required: true,
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    onConfirm() {
      const res = this.$refs.indexRef.onValid();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      this.$emit("confirm", res.data);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
