//修改密码要求
export const rules = {
    //默认
    '': {
        isDefault: true,
        min: 6,
        requireNumber: true,
        requireLetter: true,
        requireSymbol: false,
        caseSensitive: true,
        pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/
    },


    //【测试】ESS测试
    '0b63bec51b5f47ee8d7cff64938ea880': {
        isDefault: false,
        min: 8,
        requireNumber: true,
        requireLetter: true,
        requireSymbol: true,
        caseSensitive: true,
        pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/
    },

    //【生产】同方威视技术股份有限公司
    '50267726b8674227a0c1b3a379c05c41': {
        isDefault: false,
        min: 8,
        requireNumber: true,
        requireLetter: true,
        requireSymbol: true,
        caseSensitive: false,
        pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/
    },
}