<!--找回密码弹窗-->
<template>
  <a-modal
    class="user-reset-password"
    v-model="innerVisible"
    :title="$t('user.resetPwd.title')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    destroyOnClose
    :maskClosable="false"
  >
    <a-form :form="form" class="user-password-form">
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-input
          size="large"
          v-decorator="[
            'username',
            {
              rules: [
                { required: true, message: $t('user.username.validation') },
                { pattern: REG_MOBILE(), message: $t('user.username.mobile') },
              ],
            },
          ]"
          :placeholder="$t('user.username.placeholder')"
          autocomplete="off"
        >
          <img src="@/assets/images/user/password/LoginIco_User.png" alt="" slot="prefix" class="user-icon icon-phone" />
        </a-input>
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        class="user-password-form-item-code"
      >
        <a-input
          size="large"
          :placeholder="$t('user.mobileCode.placeholder')"
          autocomplete="off"
          v-decorator="[
            'mobileCode',
            {
              rules: [
                { required: true, message: $t('user.mobileCode.validation') },
              ],
            },
          ]"
        >
          <img src="@/assets/images/user/password/LoginIco_Verify.png" alt="" slot="prefix" class="user-icon icon-code" />
          <a-button
            slot="suffix"
            type="link"
            class="password-suffix"
            @click="onCode"
            :disabled="codeCounter > 0"
          >
            {{ codeCounter > 0 ? `${codeCounter}${$t('user.mobileCode.counterText')}` : $t('user.mobileCode.suffix')}}
          </a-button>
        </a-input>
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-input-password
          size="large"
          type="password"
          v-decorator="[
            'password',
            {
              rules: passwordFieldRule
            },
          ]"
          :placeholder="$t('user.password.placeholderNew')"
          autocomplete="off"
        >
          <img src="@/assets/images/user/password/LoginIco_PassWord.png" alt="" slot="prefix" class="user-icon icon-pwd" />
        </a-input-password>
      </a-form-item>
      <div class="user-password-tips">
        <img src="@/assets/images/user/password/PublicIco_prompt.png" alt="" class="user-icon icon-tips" />
        <span>{{passwordTip}}</span>
      </div>
    </a-form>
  </a-modal>
</template>

<script type="text/javascript">
import { formItemLayout, formTailLayout } from "@/pages/user/config";
import { resetPwd, resetPwdSendCode } from "@/pages/user/api";
import { FETCH_CODE, REG_MOBILE, REG_PASSWORD } from "@/config";
import { showMsg } from "@/utils";
import { trim } from "lodash";
import { rules as PasswordRules} from '@/config/custom/password-rule';

export default {
  name: "UserPassword",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, {
        name: "userPassword",
      }),
      codeTimer: 0,
      codeCounter: 0,
      pending: false,
      passwordFieldRule: [
        { required: true, message: this.$t('user.password.validation') },
        { min: 6, message: this.$t('user.password.minLength') },
        { max: 24, message: this.$t('user.password.maxLength', { length: 24 }) },
        { pattern: REG_PASSWORD, message: this.$t('user.password.strong') },
      ],
      passwordTip: this.$t('user.resetPwd.tips')
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    }
  },
  methods: {
    REG_MOBILE() {
      return REG_MOBILE
    },
    // 关闭
    onClose() {
      this.form.resetFields();
      this.$emit("update:visible", false);
    },
    // 重置密码
    onConfirm() {
      if (this.pending) return;
      this.form.validateFields(async (errors, values) => {
        if (errors) return;
        this.pending = true;
        const res = await resetPwd({
          mobile: trim(values.username),
          code: values.mobileCode,
          password: values.password,
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.pending = false;
          return;
        }
        showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t('showMsg.success.change') });
        this.pending = false;
        this.onClose();
      });
    },
    // 发送验证码
    async onCode() {
      if (this.codeCounter > 0) return;
      this.form.validateFields(["username"], async (errors, values) => {
        if (errors) {
          return;
        }
        // 校验通过
        const res = await resetPwdSendCode({
          mobile: values.username,
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          return;
        }
        //更新密码策略
        if (res.data && res.data.length > 0) {
          this.initPasswordRule(res.data);
        }
        // 请求成功 开始倒计时
        this.codeCounter = 60;
        clearInterval(this.codeTimer);
        this.codeTimer = setInterval(() => {
          this.codeCounter = this.codeCounter - 1;
          if (this.codeCounter < 1) {
            clearInterval(this.codeTimer);
          }
        }, 1000);
      });
    },
    initPasswordRule(companyId) {
      console.log(this.passwordFieldRule);

      const pwdRule = PasswordRules[companyId] || PasswordRules[''];

      if (pwdRule.isDefault) {
        return;
      }

      let typeRequirement = [];

      if (pwdRule.requireNumber) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeNumber'));
      }

      if (pwdRule.requireLetter) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeLetter'));
      }

      if (pwdRule.requireSymbol) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeSymbol'));
      }

      let tipMsg = this.$t('user.resetPwd.requiredLength', {length: pwdRule.min});

      if (typeRequirement.length > 0) {
        tipMsg += `, ${this.$t('user.resetPwd.requiredType', {type: typeRequirement.toString()})}`;
      }

      if (pwdRule.caseSensitive) {
        tipMsg += `, ${this.$t('user.resetPwd.caseSensitive')}`;
      }

      this.passwordTip = tipMsg;

      this.passwordFieldRule[1].min = pwdRule.min;
      this.passwordFieldRule[1].message = '';
      
      this.passwordFieldRule[3].pattern = pwdRule.pattern;
      this.passwordFieldRule[3].message = tipMsg;
    }
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.user-reset-password {

  /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 43px;
  }
  .password-suffix {
    font-size: 16px;
    padding: 0;
  }
  .user-password-form {
    &-item-code {
      /deep/ .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 150px;
      }
    }
  }
  .user-password-tips {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }
}
</style>
