<template>
  <div class="sign-up-form-wrapper">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      class="sign-up-form"
      :label-col="calcFormItemLayout([4, 7]).labelCol"
      :wrapper-col="calcFormItemLayout([4, 7]).wrapperCol"
    >
      <h3 class="form-title">{{ $t("user.signUpTitle") }}</h3>
      <h3 class="form-title sub">{{ $t("user.signUpTitleSub") }}</h3>
      <div class="form-content">
        <div class="form-item">
          <a-form-model-item
            :label="$t('member.base.form.mobile')"
            prop="contact"
          >
            <a-input
              size="large"
              v-model="form.contact"
              :placeholder="$t('user.username.placeholder')"
              autocomplete="off"
              @keyup.enter="onNextFocus('userSignUp_company')"
              id="userSignUp_contact"
            />
          </a-form-model-item>
        </div>
        <div class="form-item">
          <a-form-model-item :label="$t('user.company.label')" prop="company">
            <a-input
              size="large"
              v-model="form.company"
              :placeholder="$t('user.company.placeholder')"
              autocomplete="off"
              @keyup.enter="onNextFocus('userSignUp_name')"
              id="userSignUp_company"
            />
          </a-form-model-item>
        </div>
        <div class="form-item">
          <a-form-model-item :label="$t('user.name.label')" prop="name">
            <a-input
              size="large"
              v-model="form.name"
              :placeholder="$t('user.name.placeholder')"
              autocomplete="off"
              @keyup.enter="onNextFocus('userSignUp_remark')"
              id="userSignUp_name"
            />
          </a-form-model-item>
        </div>
        <div class="form-item">
          <a-form-model-item :label="$t('user.remark.label')" prop="remark">
            <a-input
              size="large"
              v-model="form.remark"
              :placeholder="$t('user.remark.placeholder')"
              autocomplete="off"
              @keyup.enter="onNextFocus('userSignUp_code')"
              id="userSignUp_remark"
            />
          </a-form-model-item>
        </div>
        <div class="form-item">
          <a-form-model-item :label="$t('user.code.label')" prop="code">
            <a-input
              size="large"
              v-model="form.code"
              :placeholder="$t('user.code.placeholder')"
              autocomplete="off"
              @keyup.enter="onSubmit"
              id="userSignUp_code"
            >
              <img
                class="form-code-img"
                slot="addonAfter"
                :src="codeImage"
                :alt="$t('user.reloadCodeImg')"
                @click="getCodeImg"
              />
            </a-input>
          </a-form-model-item>
        </div>
      </div>
      <div class="form-foot">
        <a-button
          type="primary"
          @click="onSubmit"
          class="form-submit"
          :loading="pending"
        >
          {{ $t("user.signUp") }}
        </a-button>
      </div>
      <div class="form-foot-link-wrap">
        {{ $t("user.signInTip")
        }}<a
          class="form-foot-link"
          href="javascript: void(0);"
          @click="onSignIn"
        >
          {{ $t("user.signInSubTip") }}
        </a>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { cloneDeep, trim } from "lodash";
import { cookies, FETCH_CODE, REG_MOBILE } from "@/config";
import { getImageCode, submitContact } from "@/pages/user/api";
import { showMsg } from "@/utils";
import { langMixin } from "@/mixins";

const Empty = {
  appId: cookies.EC_APP_ID,
  contact: "",
  company: "",
  name: "",
  remark: "",
  code: ""
};

export default {
  name: "UserSignUpForm",
  mixins: [langMixin],
  data() {
    return {
      form: cloneDeep(Empty),
      rules: {
        contact: [
          { required: true, message: this.$t("user.username.validation") },
          { pattern: REG_MOBILE, message: this.$t("user.username.mobile") }
        ],
        company: [
          { required: true, message: this.$t("user.company.validation") }
        ],
        name: [{ required: true, message: this.$t("user.name.validation") }],
        remark: [
          { required: true, message: this.$t("user.remark.validation") }
        ],
        code: [{ required: true, message: this.$t("user.code.validation") }]
      },
      pending: false,
      codeImage: "",
      codeImagePending: false
    };
  },
  methods: {
    onNextFocus(id) {
      try {
        const target = document.getElementById(id);
        target.focus();
      } catch (err) {
        console.error(err);
      }
    },
    async onSubmit() {
      if (this.pending) return;
      this.pending = true;
      this.$refs.formRef.validate(async (valid, errors) => {
        if (!valid) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: Object.values(errors)[0]?.[0]?.message
          });
          this.pending = false;
          return;
        }
        // 请求登录接口
        const res = await submitContact({
          appId: Empty.appId,
          contact: trim(this.form.contact),
          company: trim(this.form.company),
          name: trim(this.form.name),
          remark: trim(this.form.remark),
          code: trim(this.form.code)
        });
        console.log(res);
        // 注册失败
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          await showMsg(res);
          this.pending = false;
          return;
        }
        // 注册成功
        this.$success({
          title: this.$t("showMsg.success.submit"),
          // JSX support
          content: this.$t("user.signUpDone"),
          onOk: () => {
            this.pending = false;
            this.onSignIn();
          }
        });
      });
    },
    onSignIn() {
      this.$router.replace("/user/sign-in");
    },
    async getCodeImg() {
      if (this.codeImagePending) return;
      this.codeImagePending = true;
      const res = await getImageCode({
        width: 102,
        height: 46
      });
      this.codeImage = res.data;
      this.codeImagePending = false;
    }
  },
  mounted() {
    this.getCodeImg();
    if (this.$route.query.contact) {
      this.form.contact = this.$route.query.contact;
    }
  }
};
</script>

<style lang="less" scoped>
.sign-up-form {
  width: 539px;
  position: relative;
  padding: 10px;

  .form-title {
    font-size: 48px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.9);
    line-height: 59px;
    margin: 0;

    &.sub {
      margin-top: 10px;
      font-size: 22px;
      line-height: 32px;
      font-weight: normal;
    }
  }
  .form-content {
    margin-top: 15px;
    /deep/.ant-form-item-label .ant-form-item-required {
      color: #a3aab2;
    }
  }
  .form-foot {
    margin-top: 47px;

    .form-submit {
      width: 100%;
    }

    &-link {
      &-wrap {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 400;
        color: #aab0b8;
        text-align: center;
      }
    }
  }
  .form-code-img {
    display: block;
    height: 46px;
    width: 102px;
    cursor: pointer;
    text-align: center;
    line-height: 46px;
  }
}
</style>
