var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"user-reset-password",attrs:{"title":_vm.$t('user.resetPwd.title'),"centered":"","destroyOnClose":"","maskClosable":false},on:{"ok":_vm.onConfirm,"cancel":_vm.onClose},model:{value:(_vm.innerVisible),callback:function ($$v) {_vm.innerVisible=$$v},expression:"innerVisible"}},[_c('a-form',{staticClass:"user-password-form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            rules: [
              { required: true, message: _vm.$t('user.username.validation') },
              { pattern: _vm.REG_MOBILE(), message: _vm.$t('user.username.mobile') },
            ],
          },
        ]),expression:"[\n          'username',\n          {\n            rules: [\n              { required: true, message: $t('user.username.validation') },\n              { pattern: REG_MOBILE(), message: $t('user.username.mobile') },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.username.placeholder'),"autocomplete":"off"}},[_c('img',{staticClass:"user-icon icon-phone",attrs:{"slot":"prefix","src":require("@/assets/images/user/password/LoginIco_User.png"),"alt":""},slot:"prefix"})])],1),_c('a-form-item',{staticClass:"user-password-form-item-code",attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobileCode',
          {
            rules: [
              { required: true, message: _vm.$t('user.mobileCode.validation') },
            ],
          },
        ]),expression:"[\n          'mobileCode',\n          {\n            rules: [\n              { required: true, message: $t('user.mobileCode.validation') },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.mobileCode.placeholder'),"autocomplete":"off"}},[_c('img',{staticClass:"user-icon icon-code",attrs:{"slot":"prefix","src":require("@/assets/images/user/password/LoginIco_Verify.png"),"alt":""},slot:"prefix"}),_c('a-button',{staticClass:"password-suffix",attrs:{"slot":"suffix","type":"link","disabled":_vm.codeCounter > 0},on:{"click":_vm.onCode},slot:"suffix"},[_vm._v(" "+_vm._s(_vm.codeCounter > 0 ? `${_vm.codeCounter}${_vm.$t('user.mobileCode.counterText')}` : _vm.$t('user.mobileCode.suffix'))+" ")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: _vm.passwordFieldRule
          },
        ]),expression:"[\n          'password',\n          {\n            rules: passwordFieldRule\n          },\n        ]"}],attrs:{"size":"large","type":"password","placeholder":_vm.$t('user.password.placeholderNew'),"autocomplete":"off"}},[_c('img',{staticClass:"user-icon icon-pwd",attrs:{"slot":"prefix","src":require("@/assets/images/user/password/LoginIco_PassWord.png"),"alt":""},slot:"prefix"})])],1),_c('div',{staticClass:"user-password-tips"},[_c('img',{staticClass:"user-icon icon-tips",attrs:{"src":require("@/assets/images/user/password/PublicIco_prompt.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.passwordTip))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }