<template>
  <user-sign-logo class="sign-in-wrapper">
    <UserSignInForm :copyright="copyright" />
  </user-sign-logo>
</template>

<script>
import UserSignInForm from "@/pages/user/SignInForm.vue";
import UserSignLogo from "@/pages/user/Logo.vue";

export default {
  name: "UserSignIn",
  components: {
    UserSignInForm,
    UserSignLogo,
  },
  data() {
    return {
      copyright: `Copyright @ 2021-${new Date().getFullYear()} echeck. All Rights Reserved`
    };
  },
  computed: {},
  methods: {}
};
</script>

<style lang="less" scoped>
.sign-in-wrapper {
}
</style>
