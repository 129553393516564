<template>
  <div class="ec-radio-group">
    <div
      :class="`ec-radio ${ selected === radio.companyId ? 'active' : '' }`"
      v-for="radio in companyList"
      :key="radio.companyId"
      @click="onChange(radio.companyId, radio)"
    >
      <div class="ec-radio-label">{{ radio.name }}</div>
      <div class="ec-radio-icon">
        <a-icon type="check" />
      </div>
      <input
        type="radio"
        class="ec-radio-control"
        :checked="`${ selected === radio.companyId }`"
        name="company"
      />
    </div>
  </div>
</template>

<script>
import { FETCH_CODE, STORAGE_KEYS } from "@/config";
import { userCompanyList } from "@/pages/user/api";

export default {
  name: "UserSelectCompany",
  props: {
    username: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selected: null,
      selectedCompany: {},
      companyList: [],
    };
  },
  methods: {
    onChange(value, option) {
      this.selected = value;
      this.selectedCompany = {
        ...option,
      };
    },
    async getCompanyList() {
      const res = await userCompanyList({
        account: this.username,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.companyList = res.data;
      const companyId = window.sessionStorage.getItem(STORAGE_KEYS.COMPANY);
      if (!companyId) return;
      const curr = res.data.find((it) => {
        return it.companyId === companyId;
      });
      if (!curr) return;
      this.selected = companyId;
      this.selectedCompany = { ...curr };
    },
    onValid() {
      const result = {
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: "",
        data: {
          companyId: this.selected,
          company: this.selectedCompany,
        },
      };
      if (!this.selected) {
        result.flag = FETCH_CODE.WARNING.KEY;
        result.msg = this.$t("user.selectCompany");
      }
      return result
    }
  },
  mounted() {
    this.getCompanyList();
  },
};
</script>

<style lang="less" scoped>
.ec-radio-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 6px;

  .ec-radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 12px 20px 12px 14px;
    cursor: pointer;
    gap: 10px;
    transition: all 0.3s ease-out;
    position: relative;

    &-label {
      flex: 1;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: #757E8C;
    }

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 14px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: #CCD8F0;
      color: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
    }

    &-control {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: -99;
      display: block;
      width: 0;
      height: 0;
    }

    &:hover {
      border-color: #CCD8F0;
    }

    &.active {
      border-color: @primary-color;

      .ec-radio-label {
        color: @primary-color;
      }

      .ec-radio-icon {
        background: @primary-color;
      }
    }
  }
}
</style>
