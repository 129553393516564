<template>
  <div class="sign-wrapper" :class="target">
    <h1 class="sign-logo" :title="$t('app.name')">
      <img :src="logo" alt="" />
    </h1>
    <slot></slot>
  </div>
</template>

<script>
import cssLogo from "@/assets/images/logo01@2x.png";
import hrmanyLogo from "@/assets/images/logo02@2x.png";

export default {
  name: "UserSignLogo",
  components: {},
  data() {
    return {};
  },
  computed: {
    target() {
      if (
        process.env.VUE_APP_CLIENT === "hrmany" ||
        /hrmany\.com$/.test(window.location.hostname)
      ) {
        return "hrmany";
      }
      return "css";
    },
    logo() {
      if (this.target === "hrmany") {
        return hrmanyLogo;
      }
      return cssLogo;
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.sign-wrapper {
  width: 100%;
  // {/* height: 100%; */}
  min-height: 100vh;
  padding-top: 92px;

  &.css {
    background: url("../../assets/images/user/right-bg01@2x.png") no-repeat right top / auto 100%;

    .sign-logo {
      width: 191px;
      height: 49px;
      margin-left: 89px;
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .sign-in-form-wrapper,
  .sign-up-form-wrapper {
    margin-top: 97px;
    margin-left: 13.65%;
  }

  &.hrmany {
    padding-top: 59px;
    background: url("../../assets/images/user/left-bg02@2x.png") no-repeat left top / auto 100%;


    .sign-logo {
      width: 205px;
      height: 44px;
      margin-left: 75px;
    }

    .sign-in-form-wrapper,
    .sign-up-form-wrapper {
      margin-top: unset;
      margin-left: unset;
      top: 22%;
      right: 15.73%;
      position: absolute;
      z-index: 1;
    }
  }
}
</style>
