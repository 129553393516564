<template>
  <a-modal
    class="user-sms-login"
    v-model="innerVisible"
    :title="$t('user.mobileVerify')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    :maskClosable="false"
    destroyOnClose
    :confirm-loading="pending"
  >
    <UserSMSLogin ref="indexRef" :username="username" />
  </a-modal>
</template>

<script>
import UserSMSLogin from "@/pages/user/SMSLogin/Index.vue";

export default {
  name: "UserSMSLoginModal",
  components: { UserSMSLogin },
  props: {
    visible: Boolean,
    username: {
      required: true,
      type: String
    },
    confirm: Function
  },
  data() {
    return {
      pending: false
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const data = await this.$refs.indexRef.onValid();
      if (!data) {
        this.pending = false;
        return;
      }
      await this.confirm(data);
      this.pending = false;
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped></style>
