<template>
  <div class="user-container">
    <router-view />
  </div>
</template>

<script type="text/javascript">
export default {
  name: "User",
  data() {
    return {};
  },
  computed: {
    showFoot() {
      return !this.$route.meta.hideFoot;
    },
  },
};
</script>

<style lang="less" scoped>
.user-container {
  width: 100%;
  height: 100%;

  .user-copyright {
    color: rgba(0, 0, 0, 0.4);
    font-size: 19px;
    position: fixed;
    z-index: 2;
    width: 100%;
    bottom: 75px;
    left: 258px;
  }
}
</style>
