<template>
  <user-sign-logo class="sign-up-wrapper">
    <user-sign-up-form />
  </user-sign-logo>
</template>

<script>
import UserSignLogo from "./Logo.vue";
import UserSignUpForm from "./SignUpForm.vue";

export default {
  name: "UserSignUp",
  components: {
    UserSignLogo,
    UserSignUpForm
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.sign-up-wrapper {
}
</style>
