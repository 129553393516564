import { mapState } from "vuex";

// 国际化
export const langMixin = {
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      langList: (state) => state.langList,
    }),
    isEnglish() {
      return this.lang === this.langList[0].key;
    },
    isChinese() {
      return this.lang === this.langList[1].key;
    }
  },
  methods: {
    /**
     * 计算label和control布局
     * @param {number[]} labelCol label span
     * @param {number[]=} wrapperCol control span
     * @return {{wrapperCol: {span: number}, labelCol: {span: number}}}
     */
    calcFormItemLayout(labelCol, wrapperCol = []) {
      const setSpan = (span) => {
        return { span };
      };
      const layout = {
        labelCol: setSpan(labelCol[0]),
        wrapperCol: setSpan(wrapperCol[0] ? wrapperCol[0] : 24 - labelCol[0]),
      };
      if (this.isEnglish && labelCol[1]) {
        layout.labelCol = setSpan(labelCol[1]);
        layout.wrapperCol = setSpan(wrapperCol[1] ? wrapperCol[1] : 24 - labelCol[1]);
      }
      return layout;
    },
    /**
     * 提示语拼接
     * 英文拼接时中间加空格
     * @return {string}
     */
    messageFormatter() {
      let msg = arguments[0];
      for (let i = 1; i < arguments.length; i++) {
        if (this.isEnglish) {
          msg += ` ${arguments[i]}`;
        } else {
          msg += arguments[i];
        }
      }
      return msg;
    },
    /**
     * label中英文处理
     * @param {{label:string,labelEn:string}} record
     * @return {string}
     */
    labelFormatter(record) {
      if (this.isEnglish) {
        return record.labelEn;
      }
      return record.label;
    },
    /**
     * 列表中的label中英处理
     * @param {{label:string,labelEn:string}[]} list
     * @return {{label:string}[]}
     */
    listLabelFormatter(list) {
      return list.map((it) => {
        return {
          ...it,
          label: this.labelFormatter(it),
        };
      });
    }
  }
};
