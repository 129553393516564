export const tablePagination = {
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["10", "20"],
        pageSize: 30,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `总计${total}条记录，每页显示${range[1] - range[0] + 1}条`;
        },
      },
    };
  },
};
