<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    class="user-verification-reset-password-form"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-form-model-item label="" prop="newPassword">
      <a-input-password
        size="large"
        type="password"
        v-model="form.newPassword"
        :placeholder="$t('user.password.placeholderNew')"
        autocomplete="off"
      >
        <img
          src="@/assets/images/user/password/LoginIco_PassWord.png"
          alt=""
          slot="prefix"
          class="user-icon icon-pwd"
        />
      </a-input-password>
    </a-form-model-item>
    <div class="user-password-tips">
      <img
        src="@/assets/images/user/password/PublicIco_prompt.png"
        alt=""
        class="user-icon icon-tips"
      />
      <span>{{ $t("user.resetPwd.tips") }}</span>
    </div>
  </a-form-model>
</template>

<script>
import { formItemLayout, formTailLayout } from "@/pages/user/config";
import { essPasswordUpdate } from "@/pages/user/api";
import { FETCH_CODE, REG_PASSWORD } from "@/config";
import { showMsg } from "@/utils";

const Empty = {
  newPassword: ""
};

export default {
  name: "UserVerifyResetPwd",
  props: {
    userId: String
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: { ...Empty },
      rules: {
        newPassword: [
          { required: true, message: this.$t("user.password.validation") },
          { min: 6, message: this.$t("user.password.minLength") },
          {
            max: 24,
            message: this.$t("user.password.maxLength", { length: 24 })
          },
          { pattern: REG_PASSWORD, message: this.$t("user.password.strong") }
        ]
      }
    };
  },
  methods: {
    onValid() {
      return new Promise(resolve => {
        this.$refs.formRef.validate(async (valid, errors) => {
          if (!valid) {
            resolve(false);
            return;
          }
          // 触发修改密码
          const params = {
            newPassword: this.form.newPassword,
            userId: this.userId
          };
          const res = await essPasswordUpdate(params);
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            resolve(false);
            return;
          }
          resolve(params);
        });
      });
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.user-verification-reset-password-form {
  .user-password-tips {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }
  /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 43px;
  }
  .password-suffix {
    font-size: 16px;
    padding: 0;
  }
}
</style>
