<template>
  <a-modal
      class="user-verification-code"
      v-model="innerVisible"
      :title="$t('user.verification')"
      @ok="onConfirm"
      @cancel="onClose"
      centered
      :maskClosable="false"
      destroyOnClose
      :confirm-loading="pending"
  >
    <UserVerificationCode ref="indexRef" :username="username" />
  </a-modal>
</template>

<script>
import UserVerificationCode from "@/pages/user/Code/Index.vue";

export default {
  name: "UserVerificationCodeModal",
  components: { UserVerificationCode },
  props: {
    visible: Boolean,
    username: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const data = await this.$refs.indexRef.onValid();
      if (!data) {
        this.pending = false;
        return;
      }
      this.$emit("confirm", data);
      this.pending = false;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
