<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    class="sign-in-sms-login-form"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-form-model-item label="" prop="username">
      <a-input
        size="large"
        v-model="form.username"
        :placeholder="$t('user.username.placeholder')"
        autocomplete="off"
        id="userSMSLogin_username"
        disabled
      >
        <img
          src="@/assets/images/user/password/LoginIco_User.png"
          alt=""
          slot="prefix"
          class="user-icon icon-phone"
        />
      </a-input>
    </a-form-model-item>
    <a-form-model-item
      label=""
      prop="smsCode"
      class="sign-in-verification-code-form-item-code"
    >
      <a-input
        size="large"
        v-model="form.smsCode"
        :placeholder="$t('user.mobileCode.placeholder')"
        autocomplete="off"
        id="userSMSLogin_smsCode"
      >
        <img
          src="@/assets/images/user/password/LoginIco_Verify.png"
          alt=""
          slot="prefix"
          class="user-icon icon-pwd"
        />
        <a-button
          slot="suffix"
          type="link"
          class="password-suffix"
          @click="onCode"
          :disabled="codeCounter > 0"
        >
          {{
            codeCounter > 0
              ? `${codeCounter}${$t("user.mobileCode.counterText")}`
              : $t("user.mobileCode.suffix")
          }}
        </a-button>
      </a-input>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { signInSendCode } from "@/pages/user/api";
import { formItemLayout, formTailLayout } from "@/pages/user/config";
import { showMsg } from "@/utils";

const Empty = {
  username: "",
  smsCode: ""
};

export default {
  name: "UserSMSLogin",
  props: {
    username: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: { ...Empty },
      rules: {
        username: [
          { required: true, message: this.$t("user.username.validation") }
        ],
        smsCode: [
          { required: true, message: this.$t("user.mobileCode.validation") }
        ]
      },
      codeTimer: 0,
      codeCounter: 0
    };
  },
  methods: {
    onValid() {
      return new Promise(resolve => {
        this.$refs.formRef.validate(async (valid, errors) => {
          if (!valid) {
            resolve(false);
            return;
          }
          resolve(this.form);
        });
      });
    },
    // 发送验证码
    async onCode() {
      if (this.codeCounter > 0) return;
      this.$refs.formRef.validateField(["username"], async errors => {
        if (errors) {
          return;
        }
        // 校验通过
        const res = await signInSendCode({
          mobile: this.form.username
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          return;
        }
        // 请求成功 开始倒计时
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("user.mobileCode.send")
        });
        this.codeCounter = 60;
        clearInterval(this.codeTimer);
        this.codeTimer = setInterval(() => {
          this.codeCounter = this.codeCounter - 1;
          if (this.codeCounter < 1) {
            clearInterval(this.codeTimer);
          }
        }, 1000);
      });
    }
  },
  mounted() {
    this.form.username = this.username;
    // 自动发送验证码
    this.onCode();
  }
};
</script>

<style lang="less" scoped>
.sign-in-sms-login-form {
  /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 43px;
  }
  .password-suffix {
    font-size: 16px;
    padding: 0;
  }
  &-item-code {
    /deep/ .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 150px;
    }
  }
}
</style>
